import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import './ServiceDetail.css'; // Import CSS for styling

const environments = {
    '366900496230': {
        name: 'Development',
        background: 'development'
    },
    '832492651218': {
        name: 'Staging',
        background: 'staging'
    },
    '654654261442': {
        name: 'Prod-Cell-One',
        background: 'prod-cell-one'
    }
};

function ServiceDetail() {
    const { serviceName, clusterName } = useParams(); // Get serviceName and clusterName from params
    const navigate = useNavigate(); // Initialize useNavigate
    const [service, setService] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState({}); // Store selected version per environment
    const [showModal, setShowModal] = useState(false);
    const [currentRedeployInfo, setCurrentRedeployInfo] = useState({});

    useEffect(() => {
        fetchServiceDetail();
    }, [serviceName, clusterName]); // Fetch details when serviceName or clusterName changes

    const fetchServiceDetail = async () => {
        try {
            const response = await axios.get(`/api/service`, {
                params: {
                    service_name: `${serviceName}`,
                    cluster_name: clusterName
                }
            });
            setService(response.data);
        } catch (error) {
            console.error('Error fetching service detail:', error);
        }
    };

    const handleDeploy = async (accountId) => {
        const service_name = serviceName.replace(/-service/g, '')
        const body = {
            service_name,
            tag: selectedVersion[accountId],
            account_id: accountId,
            cluster_name: clusterName
        };
        try {
            await axios.post('/api/deploy-fargate', body);
            alert('Deployment initiated successfully');
            fetchServiceDetail();  // Refresh service details
        } catch (error) {
            console.error('Error deploying service:', error);
            alert('Deployment failed');
        }
    };

    const handleRedeployClick = (accountId, activeVersion) => {
        if (environments[accountId].name === 'Prod-Cell-One') {
            setCurrentRedeployInfo({ accountId, serviceName, version: activeVersion });
            setShowModal(true);
        } else {
            handleDeploy(accountId);
        }
    };

    const handleConfirmRedeploy = () => {
        const { accountId, serviceName, version } = currentRedeployInfo;
        handleDeploy(accountId);
        setShowModal(false);
    };

    if (!service) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-3" onClick={() => navigate('/')}>Back to Dashboard</button> {/* Back button */}
            <h1>{serviceName}</h1>
            {service.active_version.map((versionInfo) => {
                const accountId = Object.keys(versionInfo)[0];
                const activeVersion = Object.values(versionInfo)[0];
                const serviceTags = service.service_tags[accountId].reduce((acc, { key, value }) => {
                    acc[key] = value;
                    return acc;
                }, {});

                console.log(serviceTags)

                return (
                    <div key={accountId} className={`card mb-4 ${environments[accountId].background}`}>
                        <div className="card-body">
                            <h2 className={`d-flex justify-content-between align-items-center`}>
                                {environments[accountId].name}

                                <strong>{activeVersion}</strong>
                                <button
                                    className="btn btn-light mt-2"
                                    onClick={() => handleRedeployClick(accountId, activeVersion)}
                                >
                                    Redeploy
                                </button>
                            </h2>
                            {/* <p><strong>Last Deploy Date:</strong> {lastDeployDate}</p> */}
                            <div className="mb-3 d-flex justify-content-between">
                                <label htmlFor={`versionSelect-${accountId}`} className="form-label">Select Version to Deploy</label>
                                <select
                                    id={`versionSelect-${accountId}`}
                                    className="form-select form-select-sm"
                                    value={selectedVersion[accountId] || activeVersion}
                                    onChange={(e) => setSelectedVersion(prev => ({ ...prev, [accountId]: e.target.value }))}
                                >
                                    {service.available_versions.map(version => (
                                        <option key={version} value={version}>{version}</option>
                                    ))}
                                </select>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleDeploy(accountId)}
                                    disabled={!selectedVersion[accountId]}
                                >
                                    Deploy
                                </button>
                            </div>
                            <p><strong>Project Tag:</strong> {serviceTags.project}</p>
                            <p><strong>Service Tag:</strong> {serviceTags.service}</p>
                            <p><strong>Team Tag:</strong> {serviceTags.team}</p>
                            <p>
                                <strong>Workspace Tag:</strong>
                                <a href={`https://app.terraform.io/app/Jump_Engineering/workspaces/${serviceTags.workspace}`} target="_blank" rel="noopener noreferrer">
                                    {serviceTags.workspace}
                                </a>
                            </p>
                        </div>
                    </div>
                );
            })}

            {/* Modal for confirmation */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h4>Are you sure you want to deploy production?</h4>
                        <button className="btn btn-danger" onClick={handleConfirmRedeploy}>Yes</button>
                        <button className="btn btn-secondary" onClick={() => setShowModal(false)}>No</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ServiceDetail;
