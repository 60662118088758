import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import axios from 'axios';

const environmentOptions = [
    { id: '366900496230', value: 'dev', label: 'Development', pillColor: 'success' },
    { id: '832492651218', value: 'prod', label: 'Staging', pillColor: 'secondary' },
    { id: '654654261442', value: 'prod-cell-one', label: 'Prod-Cell-One', pillColor: 'danger' }
];

const clusterOptions = [
    'pro-api-services',
    'fan-services',
    'core-services'
];

function Dashboard(props) {
    console.log(props)
    const [services, setServices] = useState([]);
    const [lambdas, setLambdas] = useState([]); // New state for Lambdas
    const [environmentFilter, setEnvironmentFilter] = useState('');
    const [clusterFilter, setClusterFilter] = useState('');
    const [filterType, setFilterType] = useState('both'); // Filter for Lambdas, Fargates, or Both
    const [teamFilter, setTeamFilter] = useState('');
    const [searchQuery, setSearchQuery] = useState(''); // New state for search query
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        fetchServices();
        fetchLambdas(); // Fetch Lambdas on component mount
    }, []);

    const fetchServices = async () => {
        try {
            const response = await axios.get('/api/list-ecs-services');

            console.log(response.data)
            // Use a Map to consolidate services by serviceName
            const serviceMap = new Map();

            Object.entries(response.data).forEach(([accountId, clusters]) => {
                clusters.forEach(cluster => {
                    const clusterKey = Object.keys(cluster)[0]; // Get the cluster ARN
                    const clusterName = clusterKey.split("/")[1]
                    const clusterData = cluster[clusterKey];
                    const serviceName = clusterData.service_name; // Extract service name
                    const team = clusterData.tags.find(tag => tag.key === 'team')?.value; // Extract team
                    const version = clusterData.tags.find(tag => tag.key === 'version')?.value || ''; // Extract version
                    const environment = clusterData.tags.find(tag => tag.key === 'env')?.value; // Extract environment
                    // Check if the service name has a number for preview handling
                    const numberMatch = serviceName.match(/-(\d+)-/); // Match numbers surrounded by hyphens
                    if (numberMatch) {
                        const strippedName = serviceName.replace(/-\d+-/, '-'); // Strip out the number and surrounding hyphens
                        const previewNumber = numberMatch[1]; // Get the number part for the preview

                        // Check if the stripped name exists in the serviceMap
                        if (serviceMap.has(strippedName)) {
                            // If it exists, add the preview number to the existing service's previews
                            serviceMap.get(strippedName).previews.push(previewNumber);
                        } else {
                            // If it doesn't exist, create a new service object with the preview number
                            serviceMap.set(strippedName, {
                                clusterName: clusterName,
                                serviceName: strippedName,
                                environments: [],
                                team, // Set team to null or handle as needed
                                previews: [previewNumber] // Initialize previews with the number
                            });
                        }

                        return
                    }

                    // Check if the serviceName already exists in the map
                    if (!serviceMap.has(serviceName)) {
                        serviceMap.set(serviceName, {
                            clusterName: clusterName,
                            serviceName,
                            environments: [{
                                version,
                                name: environment
                            }],
                            team,
                            previews: [] // Initialize previews array
                        });
                    } else {
                        // If it exists, add the new environment/version if not already present
                        const existingService = serviceMap.get(serviceName);
                        const existingEnvironment = existingService.environments.find(env => env.name === environment && env.version === version);

                        if (!existingEnvironment) {
                            existingService.environments.push({
                                version,
                                name: environment
                            });
                        }
                    }
                });
            });

            // Convert the Map values to an array
            const transformedServices = Array.from(serviceMap.values());
            setServices(transformedServices);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    const fetchLambdas = async () => {
        try {
            const response = await axios.get('/api/list-lambdas');
            const lambdaData = response.data;
            console.log(lambdaData)
            const lambdaMap = new Map();

            Object.entries(lambdaData).forEach(([accountId, functions]) => {
                functions.forEach(func => {
                    const functionName = func.FunctionName
                    const team = func.Tags?.team
                    const version = func.Tags?.version
                    const project = func.Tags?.project
                    const environment = func.Tags?.env


                    const numberMatch = functionName.match(/-(\d+)/)

                    if (numberMatch) {
                        const strippedName = functionName.replace(/-\d+/, ''); // Strip out the number and surrounding hyphens
                        const previewNumber = numberMatch[1];

                        if (lambdaMap.has(strippedName)) {
                            lambdaMap.get(strippedName).previews.push(previewNumber);
                        } else {
                            lambdaMap.set(strippedName, {
                                functionName: strippedName,
                                environments: [],
                                team,
                                previews: [previewNumber]
                            });
                        }

                        return
                    }

                    if (!lambdaMap.has(functionName)) {
                        lambdaMap.set(functionName, {
                            functionName,
                            environments: [{
                                version,
                                name: environment
                            }],
                            team,
                            previews: []
                        });
                    } else {
                        // If it exists, add the new environment/version if not already present
                        const existingLambda = lambdaMap.get(functionName);
                        const existingEnvironment = existingLambda.environments.find(env => env.name === environment);

                        if (!existingEnvironment) {
                            existingLambda.environments.push({
                                version,
                                name: environment
                            });
                        }
                    }
                })
            })

            const transformedLambdas = Array.from(lambdaMap.values())
            setLambdas(transformedLambdas);
        } catch (error) {
            console.error('Error fetching lambdas:', error);
        }
    };




    const getTeamFromCluster = (clusterName) => {
        switch (clusterName) {
            case 'fan-services':
                return 'b2c';
            case 'pro-api-services':
                return 'b2b';
            case 'core-services':
                return 'core';
            default:
                return 'devops';
        }
    };

    const filteredServices = services.filter(service => {
        const matchesEnvironment = environmentFilter ? service.environments.map(env => env.name).includes(environmentFilter) : true;
        const matchesCluster = clusterFilter ? service.clusterName === clusterFilter : true;
        const matchesTeam = teamFilter ? service.team === teamFilter : true;
        const matchesType = filterType === 'both' || (filterType === 'fargates' && service.clusterName) || (filterType === 'lambdas' && !service.clusterName);
        const matchesSearch = searchQuery ? service.serviceName.toLowerCase().includes(searchQuery.toLowerCase()) : true;
        return matchesEnvironment && matchesCluster && matchesTeam && matchesType && matchesSearch;
    });

    const filteredLambdas = lambdas.length > 0 && lambdas.filter(lambda => {
        const matchesTeam = teamFilter ? lambda.team === teamFilter : true;
        const matchesType = filterType === 'both' || (filterType === 'lambdas');
        const matchesSearch = searchQuery ? lambda.functionName.toLowerCase().includes(searchQuery.toLowerCase()) : true; // Search filter
        return matchesTeam && matchesType && matchesSearch; // Include search filter
    });

    // Render the formatted services and lambdas
    return (
        <div className="container mt-5">
            <h1>AWS Services Dashboard</h1>
            <div className="mb-3">
                <label htmlFor="searchQuery" className="form-label">Search</label>
                <input
                    type="text"
                    id="searchQuery"
                    className="form-control"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by service name or function name"
                />
                <label htmlFor="environmentFilter" className="form-label mt-2">Environment</label>
                <select
                    id="environmentFilter"
                    className="form-select"
                    value={environmentFilter}
                    onChange={(e) => setEnvironmentFilter(e.target.value)}
                >
                    <option value="">Select Environment</option>
                    {environmentOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>

                <label htmlFor="clusterFilter" className="form-label mt-2">Cluster Name</label>
                <select
                    id="clusterFilter"
                    className="form-select"
                    value={clusterFilter}
                    onChange={(e) => setClusterFilter(e.target.value)}
                >
                    <option value="">Select Cluster</option>
                    {clusterOptions.map(cluster => (
                        <option key={cluster} value={cluster}>
                            {cluster}
                        </option>
                    ))}
                </select>
                <label className="me-2">Filter by Type:</label>
                <select className="form-select" onChange={(e) => setFilterType(e.target.value)}>
                    <option value="both">Both</option>
                    <option value="lambdas">Lambdas</option>
                    <option value="fargates">Fargates</option>
                </select>
                <label className="me-2">Filter by Team:</label>
                <select className="form-select" onChange={(e) => setTeamFilter(e.target.value)}>
                    <option value="">All Teams</option>
                    <option value="core">Core</option>
                    <option value="b2b">B2B</option>
                    <option value="b2c">B2C</option>
                    <option value="devops">DevOps</option>
                </select>
            </div>
            <div className="row">
                {filteredServices.map(({ clusterName, environments, previews, serviceName, team }, index) => {
                    return (
                        <div className="col-md-4 mb-3" key={index} onClick={() => navigate(`/service/${serviceName}/${clusterName}`)}>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{serviceName}</h5>
                                    <p className="card-text">
                                        <strong>Cluster Name:</strong>
                                        <span className="badge bg-primary ms-1">{clusterName}</span><br />
                                        <strong>Environments:</strong>
                                        {environments.map((env, idx) => {
                                            // Find the corresponding environment label
                                            const environment = environmentOptions.find(option => env.name == option.value);
                                            return (
                                                <span key={idx} className={`badge bg-${environment.pillColor} me-1`}>
                                                    {environment ? environment.label : env.name}:{env.version}
                                                </span>
                                            );
                                        })}
                                        <br />
                                        <strong>Team:</strong>
                                        <span className="badge bg-info me-1">{team}</span>
                                        <br />
                                        <strong>Previews:</strong>
                                        {previews.map((preview, idx) => (
                                            <span key={idx} className="badge bg-info me-1">{preview}</span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}

                {/* Render Lambdas in a similar style */}
                {filteredLambdas && filteredLambdas.map((lambda, index) => {
                    return (
                        <div className="col-md-4 mb-3" key={index} onClick={() => navigate(`/lambda/${lambda.functionName}`)}>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{lambda.functionName}</h5>
                                    <strong>Environments:</strong>
                                    <div>
                                        {lambda.environments.map((env, idx) => {
                                            // Find the corresponding environment label
                                            const environment = environmentOptions.find(option => env.name === option.value);
                                            return (
                                                <span key={idx} className={`badge bg-${environment.pillColor} me-1`}>
                                                    {environment ? environment.label : env.name}:{env.version} {/* Fallback to ID if not found */}
                                                </span>
                                            );
                                        })}
                                    </div>
                                    <br />
                                    <strong>Team:</strong>
                                    <span className="badge bg-info me-1">{lambda.team}</span>
                                    <br />
                                    <strong>Previews:</strong>
                                    {lambda.previews.map((preview, idx) => (
                                        <span key={idx} className="badge bg-info me-1">{preview}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div >
    );
}

export default withOktaAuth(Dashboard);
