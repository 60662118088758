import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import Dashboard from './components/Dashboard';
import ServiceDetail from './components/ServiceDetail';
import LambdaDetail from './components/LambdaDetail';
import { RequiredAuth } from './components/SecureRoute';
import Loading from './components/Loading';

const clientId = "0oajlgmjyhTANVCgn697"
const oktaUrl = "jump.okta.com"

const oktaAuth = new OktaAuth({
  issuer: `https://${oktaUrl}/oauth2/default`,
  clientId: clientId,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access']
});

function App() {
  const navigate = useNavigate()

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/" element={<RequiredAuth />}>
          <Route path='' element={<Dashboard />} />
        </Route>
        <Route path="/service/:serviceName/:clusterName" element={<RequiredAuth />}>
          <Route path='' element={<ServiceDetail />} />
        </Route>
        <Route path="/lambda/:functionName" element={<LambdaDetail />} />
        <Route path="/login/callback" element={<LoginCallback loadingElement={<Loading />} />} />
      </Routes>
    </Security>
  );
}

const RouterApp = () => {
  return (
    <Router>
      <App />
    </Router>
  )
}

export default RouterApp;
